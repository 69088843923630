@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.hide-mobile {
	@screen mobile {
		display: none !important; } }
.show-mobile {
	@screen desktop {
		display: none !important; } }
.hide-tablet {
	@screen tablet {
		display: none !important; } }
